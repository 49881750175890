// Step 1: Import React
import * as React from 'react';
import Layout from '../components/Layout';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import HomePage from '../components/HomePage';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { graphql } from 'gatsby';


// Step 2: Define your component
const IndexPage = () => {
   return (
    <main>
      <Layout>
      <title>Home Page</title>
      <HomePage /> 
      </Layout>
    </main>
    )
}

// Step 3: Export your component
export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;