import * as React from 'react';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'reactstrap';
import LandingStyles from '../styles/LandingStyles';
import LandingPage from './LandingPage';
import useAuth from '../hooks/useAuth';
import { fetchUrl } from '../apiClient/baseApi';
import bookCoverImg from '../assets/images/def-book-cover1.png';
import firstBookCover from '../assets/images/first-book-cover.png';
import { BsTrash, BsDownload, BsBook, BsBookHalf, BsPencil, BsImage } from "react-icons/bs";
import { FaAngleLeft, FaEllipsisH } from "react-icons/fa";
import { AiOutlineFilePdf, AiOutlineFileZip } from "react-icons/ai";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import ReactModal from 'react-modal';
import Loader from './Loader';
import Cr8Content from './Cr8Content';


const initialModalState = {
  showebookModal: false,
  showmagazineModal: false,
  showarticleModal: false,
  shownewsletterModal: false
 }

 const licPage = '<p>All rights reserved. Copyright infringement is against the law. This book or any portion ' + 
'thereof may not be reproduced or used in any manner whatsoever without the express written permission of the author/publisher ' + 
'except for the use of brief quotations in a book review.</p>' + 
'<p>Portions of this book are works of fiction. Any references to historical events, real people, or real places are used ' + 
'fictitiously. Other names, characters, places and events are products of the author\'s imagination, and any resemblances ' + 
'to actual events or places or persons, living or dead, is entirely coincidental.</p>' 

const titFiller = '<h2><br /></h2>'

export default function HomePage() {  
    const { navigate, t } = useI18next();

    const { isAuthenticated } = useAuth()
    const [state, setState] = React.useState(initialModalState);
    const [books, setBooks] = React.useState([]); 
    const [load, setLoad] = React.useState(false)
    const [booksLoaded, setBooksLoaded] = React.useState(false)
    const [user, setUser] = React.useState([]); 
    const [lang, setLang] = React.useState(1)


  const handleebookOpenModal = () => {
      setState({ showebookModal: true });
  }

  const handlearticleOpenModal = () => {
  setState({ showarticleModal: true });
  }

  const handlemagazineOpenModal = () => {
  setState({ showmagazineModal: true });
  }

  const handlenewsletterOpenModal = () => {
    setState({ shownewsletterModal: true });
    }  

  const handleebookCloseModal = () => {
      setState({ showebookModal: false });      
  }

  const handlearticleCloseModal = () => {
  setState({ showarticleModal: false });
  }

  const handlemagazineCloseModal = () => {
  setState({ showmagazineModal: false });
  }

  const handlenewsletterCloseModal = () => {
    setState({ shownewsletterModal: false });
    }

  const cr8newCon = async (newCon) => {
    let year = new Date().getFullYear();
  
    if (newCon.conType === 'ebook') {
    let bookData = { "title": newCon.conTitle, "language" : newCon.conLang }
    let chpData = []
    let titlePage = titFiller.repeat(4) + '<h2>' + newCon.conTitle + '</h2>' + 
                      titFiller.repeat(10) + '<h2>' + user.first_name + ' ' + user.last_name + '</h2'
    let licBody = '<h3>' + newCon.conTitle + '</h3><h3>Copyright &copy; ' + year + ' ' + user.first_name + ' ' + user.last_name + '</h3>' +
                    licPage + '<h3>First Edition: ' + year + '</h3>'
    await fetchUrl('post', '/items/books', bookData).then((res) => {
      chpData.push({ "type": "title", "title": newCon.conTitle, "body": titlePage, "weight" : 1, "bid": res.data.id })
      chpData.push({ "type": "edition", "title": " ", "body": licBody, "weight" : 2, "bid": res.data.id })
      Promise.all(
        chpData.map( async (data) => {
          await fetchUrl('post',  '/items/chapters/' , data)
        })
      ).then(() => {
        fetchUrl('get', '/items/books/' + res.data.id + '?fields=*.*').then((result) => {
          console.log('created the chapters')
          // navigate to the new bookwizard page
          navigate( '/app/bookwizard/' + result.data.id, { state: { book: result.data }, }) 
        setState({ showebookModal: false })
        })
      })
    })
  }
    else if (newCon.conType === 'article') {
      setState({ showarticleModal: false })
    } 
    else if (newCon.conType === 'magazine') {
      setState({ showmagazineModal: false })
    }
    else if (newCon.conType === 'newsletter') {
      setState({ shownewsletterModal: false })
    }
  }

    const toggleBtn = (id) => {
      var parentDiv = document.getElementById('button_' + id);
      var targetDiv = parentDiv.getElementsByClassName("dropdown-bk-content")[0];
      var otherParents = document.getElementsByClassName("booktainer");
      for (var i=0; i<otherParents.length; i++) {
        if (otherParents[i].innerHTML !== parentDiv.innerHTML) {
          otherParents[i].classList.remove("no-hover");
        }
      }
      var otherDivs = document.getElementsByClassName("dropdown-bk-content");
      for ( i=0; i<otherDivs.length; i++) {
        if (otherDivs[i].innerHTML !== targetDiv.innerHTML) {
          otherDivs[i].classList.remove("dropdown-toggle");
        }
      }
  
  
      parentDiv.classList.toggle('no-hover');
      targetDiv.classList.toggle("dropdown-toggle");    
    }
  
    const makeTitleClick = (id) => {
      let title = document.getElementById('btitle_'+id);
      title.focus();
    }

    function preventEnter(event) {
      if(event.which === 13) {
        event.preventDefault();
        event.currentTarget.blur();
      }
      return event.which !== 13;
     }
  
    function saveTitle(id) {
      console.log("saving book title")
      document.getElementById('editIcon_'+ id).style.display = "block";
      let tid = 'title' + id;
      let newTitle = document.getElementById('btitle_'+id).innerText;
  
      window.localStorage.setItem(tid, newTitle );
      let data = { "title" : newTitle }
      fetchUrl('patch',  '/items/books/' + id, data)
  
      document.getElementById('btitle1_'+id).innerText = newTitle;
      document.getElementById('img_'+id).alt = 'Edit ' + newTitle;
      document.getElementById('img_'+id).title = 'Edit ' + newTitle;
  
    }
  
    const editCover = function(item) {
      navigate('/app/bookwizard/' + item.id, { state: { 'book': item, 'cover': 1 }});
    }
    
    const delBook = async function(bid) {
      //call API to delete book
      await fetchUrl('delete',  '/items/books/' + bid).then(() => {
        //Remove content from UI
        let newBooks = books.filter(b => b.id !== bid);
        setBooks(newBooks);
      })
    } 
  
    const genEbook = async function(id, type) {
    
      setLoad(true)
      await fetchUrl('get',  '/generate-ebook/' + id + '/' + type).then((fileid) => {
        
         fetchUrl('get', '/assets/' + fileid + '?timestamp=' + Math.floor(new Date().getTime() / 1000), {responseType: 'arraybuffer'}).then( res => {
           let ftype
          if ((type === 'print1') || (type === 'print2') || (type === 'pdf')) {
            type = 'pdf'
            ftype = 'application/pdf'
          }
          else if (type === 'epub') {
            ftype = 'application/epub+zip'
          }
          // Create an object URL from the data.
         var file = new Blob([res], {type: ftype});
         let fileURL = URL.createObjectURL(file)
    
         // Download the file programmatically
         var a = document.createElement('a');
         a.href = fileURL
         a.target = "_blank"
         a.download = document.getElementById('btitle_'+id).innerText + '.' + type
         a.click();
         setLoad(false)

         var parentDiv = document.getElementById('button_' + id);
         var target1Div = parentDiv.getElementsByClassName("dropdown-bk-content")[0];
         var target2Div = parentDiv.getElementsByClassName("dropdown-dwnld-content")[0];
      
         parentDiv.classList.toggle('no-hover');
         target1Div.classList.toggle("dropdown-toggle");  
         target2Div.classList.toggle("dropdown-toggle");  
    
          })
        }) 
  
    }
  
    const selectAllText = (id) => {
      document.getElementById('editIcon_'+id).style.display = "none";
  
      setTimeout(
        function() {
          document.execCommand('selectAll', false, null)
        },0)
    }
  
    const toggleDwnld = (id) => {
      var targetDiv = document.getElementById('button_' + id).getElementsByClassName("dropdown-dwnld-content")[0];
      
      targetDiv.classList.toggle("dropdown-toggle");  
    }
  
    React.useEffect( ()=>{
      if (isAuthenticated) {
        setLoad(true)

        fetchUrl('get', '/users/me?fields=*.*').then((res) => {
          window.localStorage.setItem('user', JSON.stringify(res.data));
          setUser(res.data)
        })

        const loadBooks = async function() {
          console.log('fetching books')
          await fetchUrl('get', '/items/books?fields=*.*&sort=-date_created').then((res) => {
            setBooks(res.data); 
            setBooksLoaded(true)
            setLoad(false)
          })
        }

        loadBooks();
      }
      else {
        setBooks([])
      }
     }, [isAuthenticated])

     React.useEffect(()=>{
      if(user) {
        if (user.pref_lang) {
          let lid
          if (typeof user.pref_lang == "object") {
               lid = user.pref_lang["id"]
          }
        else {
          lid = user.pref_lang
        }
          setLang(lid)
        }
      }
     }, [user])
  
     React.useEffect(()=> {
       if (isAuthenticated) {
        books.forEach(async (item, index) => {
          if(item.cover) {
            await fetchUrl('assets',  item.cover).then(res => {
              document.getElementById('img_' + item.id).src = URL.createObjectURL(res);
            })
          }
        })
       }
     }, [isAuthenticated, books])
  
     const buttonStyle = {
      margin: '1rem',
      fontSize: 16,
      textShadow: 'none',
      boxShadow: 'none'
    }
  
    const buttonStyle1 = {
      margin: '1rem',
      background: '#FF5B39',
      fontSize: 16,
      textShadow: 'none',
      boxShadow: 'none'
    }
  
    if (!isAuthenticated) {
      return (
        <LandingPage />
      );
    }
    else {
    return (<>
        { booksLoaded ? 
        <LandingStyles id="homeLogged">
            <Container className="siteStyl">
            <h2>{t('home11')}</h2>
            </Container>
            { (Object.keys(books).length > 0) ? 
            <Container className="bookItem siteStyl">
            { books.map( (item, index) => {
              return (
                <div id={'button_' + item.id} key={item.id} className="booktainer">
                    <figure style={{padding: "5px"}}>
                    <Link to={`/app/bookwizard/${item.id}`} state={{ book: item }}>
                      <img id={'img_' + item.id} src={bookCoverImg} alt={'Edit ' + item.title}  title={'Edit ' + item.title}/>
                    </Link>
                      <figcaption id={'caption_' + item.id}>
                      <Container>
                        <Row>
                        <Col md="10">
                          <div id={'btitle_' + item.id} className="bk-title" contentEditable="true" onBlur={() => saveTitle(item.id)} 
                            onKeyPress={preventEnter} onFocus={() => selectAllText(item.id)}
                            suppressContentEditableWarning role="presentation">
                            {item.title}
                          </div>
                       </Col>
                       <Col md="2">
                        <div id={'editIcon_' + item.id} className="bk-editTitle">
                          <BsPencil size="25px" onClick={() => makeTitleClick(item.id)} style={{ cursor: 'pointer' }}/>
                        </div>
                        </Col>
                       </Row>
                       <Row>
                         <p style={{color: "grey"}}>
                           {item.language["name"]}
                         </p>
                       </Row>
                      </Container>
                      </figcaption>
                    </figure>
                    <div className="bk-settings" >
                      <button className="stack-top" onClick={() => toggleBtn(item.id)}>
                        <FaEllipsisH />
                      </button>
                      <div className="dropdown-bk-content">
                        <h3 id={'btitle1_' + item.id} >{item.title}</h3>
                        <hr />
                        {item.cover ? (<div><button onClick={() => toggleDwnld(item.id)}><BsDownload /> {t('home12')}</button> 
                        <div className="dropdown-dwnld-content">
                        <div style={{float: "left" }}><button onClick={() => toggleDwnld(item.id)}><FaAngleLeft />&nbsp;</button></div>
                        <div className="bk-label">{t('home12')}</div>
                        <div style={{fontSize: '0px'}}>{item.title}</div>

                        <button style={{ borderTop: 'solid 1px lightgrey' }} onClick={() => genEbook(item.id, 'epub')}><AiOutlineFileZip /> {t('home13')}</button>
                        <button onClick={() => genEbook(item.id, 'pdf')}><AiOutlineFilePdf /> {t('home14')}</button>
                        <button onClick={() => genEbook(item.id, 'print1')}><BsBookHalf /> {t('home15')}</button>
                        <button onClick={() => genEbook(item.id, 'print2')}><BsBook /> {t('home16')}</button>

                     </div></div>) : (<div></div>) } 
                        <button onClick={() => editCover(item)}><BsImage /> {t('home17')}</button>
                        <button 
                          onClick = {() => {
                            confirmAlert({
                              customUI: ({ onClose }) => {
                                return (
                                  <div className="react-confirm-alert-body">
                                    <h2>{t('home19')}</h2>
                                <p>{t('home20')} "{item.title}"?</p>
                                <div className="react-confirm-alert-button-group">
                                    <button style={buttonStyle} onClick={onClose}>{t('home21')}</button>
                                    <button style={buttonStyle1}
                                      onClick={() => {
                                        delBook(item.id);
                                        onClose();
                                      }}
                                    >
                                      {t('home22')}!
                                    </button>
                                    </div>
                                  </div>
                                );
                              }
                            });
                          }
                      }
                      ><BsTrash /> {t('home18')}</button>
                    </div>
                  </div> 
                </div>
              )
            }
          )
        }
         </Container> : <Container className="bookItem siteStyl">
            <div className="booktainer" onClick={handleebookOpenModal} id="def-grid">
              <img className='uploaded-book-cover' alt='Create your first eBook' src={firstBookCover} />
            </div>
         </Container> }
         <br />
    </LandingStyles> : <p>Loading Books...</p> }
    <ReactModal 
        isOpen={load}
        contentLabel="Content Loading"
        onRequestClose={()=> {setLoad(false)}}
        className="react-confirm-alert-body"
        overlayClassName="react-confirm-alert-overlay"
        ariaHideApp={false}
        >
      <Loader />
    </ReactModal>
    <ReactModal 
      isOpen={state.showebookModal}
      contentLabel="Create New ebook"  
      onRequestClose={handleebookCloseModal}
      className="react-confirm-alert-body"
      overlayClassName="react-confirm-alert-overlay"
      key="ebook"
      appElement={document.getElementById('homeLogged') || undefined}
      >
      <Cr8Content conType={"ebook"} close={handleebookCloseModal} addnewCon={cr8newCon} defLang={lang}/>
    </ReactModal>
    </>
    );
      }
};
