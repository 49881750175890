import styled from 'styled-components';
import lu from '../assets/images/landing-users.png';

const LandingStyles = styled.nav`
.c_primary {
  color: var(--orange);
}

.landing-page {
    position: relative;
    min-height: 461px; 
}
    
.landing-page .content-bg-wrap {
    background-image: url(${lu});
    background-position: 0 0;
    background-repeat: repeat;
    -webkit-animation: sidedownscroll 30s linear infinite;
      animation: sidedownscroll 30s linear infinite;
    background-size: auto; 
}

.landing-page .container {
  text-align: center;
  position: relative;
  color: white;
}

.content-bg-wrap {
    background-position: 0 0;
    background-repeat: repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    text-align: center;
    box-sizing: border-box;
    -webkit-animation: slide 50s linear infinite;
            animation: slide 50s linear infinite;
    will-change: background-position;
    background-size: auto; 
}

.content-bg-wrap:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    z-index: auto; 
}
    
.landing-page .content-bg-wrap:before {
    background-color: rgba(255, 94, 58, 0.95); 
}

.content-bg-wrap.bg-landing:before {
    background-color: rgba(255, 94, 58, 0.95); 
}

@keyframes sidedownscroll {
    0% {
      background-position: 0 0; }
    100% {
      background-position: -2000px 2000px; } 
    }
  
@-webkit-keyframes slide {
    from {
      background-position: 0 0; }
    to {
      background-position: -4000px 0; } 
    }

button {
  padding: 15px;
  border-radius: 0.3em;
  background-color: limegreen;
  color: black;
  box-shadow: 0 2px 0 green;

  &:hover {
    background-color: green;
    color: white;
  }
}

/* HomeStyles for Author Desk after logged in */ 
.bookItem {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-template-rows: repeat(auto-fill, 440px);
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
}

/* Create the animation... */
  @keyframes slide-in {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  /* ...and then apply it: */


.booktainer {
    position: relative;
    hr {
        margin: 0;
        color: grey;
      }

    button {
        text-shadow: none;
        box-shadow: none;
        background: white;
        color: black;
        border-radius: .2em !important;
        border: none;
        text-align: start;
       }
    
    h3{
        background: white;
        padding: 10px;
    }

    &:not(.no-hover) .bk-settings {
        display: none;
    }  

    &:hover {
         .bk-settings {
            display: block;
        }   
    }
}

.dropdown-bk-content, .dropdown-dwnld-content {
    display: none;
    top: 40px;
    right: 0;
    z-index: 1;
    position: absolute;
    margin: 20px;
    background-color: white;

    button {
        width: 100%;
        display: block;
    }

    button:hover {
        background: whitesmoke !important;
        color: black !important;
    }

}

.dropdown-dwnld-content {
    top: -20px;
    right: -20px;
    width: 200px;
}

.bk-label {
    float: right;
    vertical-align: middle;
    background: white;
    padding: 15px;
}
.dropdown-toggle {
    display: block;
    white-space: normal;
  }

.dropdown-toggle::after {
    display: none;
  }

.bookItem img {
    max-height: 380px;
    object-fit: cover;
    width: 100%;
}

.stack-top {
    top: 0;
    right: 0;
    z-index: 1;
    position: absolute;
    margin: 20px;
    border-radius: .2em;
    animation: slide-in 300ms;


    &:hover, &:focus {
        background: #FF5B39;
        color: white;
        display: block;
    }
}

.bk-title {
    max-width: 430px;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      outline: 1px ridge white;
     }
    &:focus-visible {
      outline: 1px auto white;
    }
     text-align: start;
   }

figcaption {
    .bk-editTitle {
        display: none;
    }  

    &:hover {
        .bk-editTitle {
            display: block;
        }     
    }
}

.uploaded-book-cover {
  max-height: 400px;
  overflow: hidden;
  cursor: pointer;
}

.react-confirm-alert-button-group {
  justify-content: center;
}

.react-confirm-alert-body {
  text-align: start !important; 
}
`;

export default LandingStyles;