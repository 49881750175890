import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import LandingStyles from '../styles/LandingStyles';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import { StaticImage } from "gatsby-plugin-image";
import { BiLinkExternal } from "react-icons/bi";


export default function LandingPage() {
    const { navigate, t } = useI18next();

    return (<LandingStyles>
        <div className="landing-page">
        <div className="content-bg-wrap"> </div>
            <Container className="siteStyl">
                <Row>
                <Col md="6">
                    <StaticImage src="../assets/images/Magic Author logo white.png" alt="Magic Author Logo" style={{width: "95px", height: "150px"}} imgStyle={{width: "95px", height: "150px"}}/>
                    <p>{t('home1')}</p>
                    <br />
                    <h1>{t('home2')}</h1>
                    <br />
                    <button onClick={() => navigate('/register')}> {t('home3')} </button>
                </Col>
                <Col md="6">
                    <StaticImage src="../assets/images/bookwizard.png" alt="Book Wizard" layout="fullWidth" />
                </Col>
                </Row>
            </Container>
        </div>
        <br />
        <Container className="siteStyl">
                <Row>
                    <h2><span className="c_primary">{t('home41')} </span>{t('home42')}</h2>
                    <p>{t('home5')}</p>
                    <br />
                    <h2>{t('home61')} <span className="c_primary">{t('home62')}</span></h2>
                    <p>{t('home7')}</p>
                </Row>
                <br />
                <Row>
                <Col md="6">
                    <h2><span className="c_primary">{t('home81')} </span>{t('home82')}</h2>
                    <p>{t('home9')}</p> 
                    <a href="https://help.magicauthor.com/reference/ebook-formats" target="_blank" rel="noopener noreferrer">{t('home10')} <BiLinkExternal /></a>
                </Col>
                <Col md="6">
                    <br />
                    <StaticImage src="../assets/images/ebook_formats.png" alt="ebook formats: pdf, epub, print, html" layout="fullWidth" />
                </Col>
                </Row>
                <br />
                <br />
                <br />

            </Container>
            </LandingStyles>
    );
};

